<template>
  <div class="sehen">
    <h1 class="sehen__h1">
      {{ content.name }}
    </h1>

    <ParagraphsPrePic
      v-if="content.paragraphsPrePic && content.paragraphsPrePic.length"
      class="sehen__paragraphs-pre-pic"
      :paragraphs="content.paragraphsPrePic"
    />

    <GalleryResponsive
      v-if="content.imgArr.length"
      class="sehen__gallery-responsive"
      :img-arr="content.imgArr"
    />

    <template v-if="content.videoSrcs && content.videoSrcs.length">
      <!-- Set videoWidth to smallest in videoSrcs. -->
      <VideoTheater
        v-for="(v, i) in content.videoSrcs"
        :key="v"
        class="sehen__video"
        :class="{'sehen__video--first': i === 0}"
        :src="v"
        video-width="640px"
      />
    </template>

    <SectionPostPic
      v-for="s in content.textSections"
      :key="s.header"
      :content="s"
    />

    <div class="sehen__rsv">
      <h3 class="sehen__happy">
        Wir freuen uns auf Sie
      </h3>

      <p class="sehen__rsv__p">
        Reservieren Sie Ihren Tisch <LinkRsv>online</LinkRsv> oder rufen Sie uns an unter <LinkTel />
      </p>
    </div>
  </div>
</template>

<script>
import {content} from "@/models/Sehen.js"
import {getMeta} from "@/util/route.js"
import GalleryResponsive from "@/components/GalleryResponsive.vue"
import LinkRsv from "@/components/LinkRsv.vue"
import LinkTel from "@/components/LinkTel.vue"
import ParagraphsPrePic from "@/components/ParagraphsPrePic.vue"
import SectionPostPic from "@/components/SectionPostPic.vue"
import VideoTheater from "@/components/VideoTheater.vue"

export default {
  name: "Sehen",

  components: {
    GalleryResponsive,
    LinkRsv,
    LinkTel,
    ParagraphsPrePic,
    SectionPostPic,
    VideoTheater
  },

  computed: {
    sehenKey() {
      return getMeta(this.$route.matched, "sehenKey")
    },

    content() {
      return content[this.sehenKey]
    }
  }
}
</script>

<styles lang="scss">
@use "app";
@use "txt";
@use "vars";

.sehen {
  margin-top: app.$margin-to-nav;
  margin-bottom: txt.$blockmargin;
}

.sehen__h1 {
  @include txt.sitehead;
  @include txt.bodypadding-h;
}

.sehen__paragraphs-pre-pic {
  margin-top: 1.5em;
}

.sehen__gallery-responsive {
  margin-top: 2.5em;
}

.sehen__video {
  margin-top: 6em;
}

.sehen__video--first {
  margin-top: 3em;
}

.sehen__rsv {
  margin-top: txt.$blockmargin;
  @include txt.bodypadding-h;
  text-align: center;
}

.sehen__happy {
  color: vars.$gold;
  font-size: 1.5em;
}

.sehen__rsv__p {
  margin-top: 1em;
}
</styles>
