import ImgData from "@/models/ImgData.js"
import {Content as SectionContent} from "@/components/SectionPostPic.vue"

class Sehen {
  /**
   * @param {Object} x
   * @param {String} x.name
   * @param {String[]} [x.paragraphsPrePic]
   * @param {ImgData[]} x.imgArr
   * @param {String[]} [x.videoSrcs]
   * @param {SectionContent[]} x.textSections - should contain at least one with `headingLevel: 2` for seo
   */
  constructor(x) {
    Object.assign(this, x)
  }

  get hasParagraphs() {
    const p = this.paragraphs
    return (p instanceof Array) && p.length > 0
  }
}

const optBGPosT = {bgPos: "top"}

export const content = {
  essraum: new Sehen({
    name: "Essraum",
    paragraphsPrePic: [
      "Unser Gourmet Restaurant im Obergeschoss."
    ],
    imgArr: [
      new ImgData("/img/essraum2.jpg", "Sessel im Essraum"),
      new ImgData("/img/essraum1.jpg", "Essraum Panorama"),
      new ImgData("/img/essraum3.jpg", "Speisen auf grüner Platte"),
      new ImgData("/img/essraum4.jpg", "Gericht im goldenen Teller"),
      new ImgData("/img/essraum5.jpg", "Gericht auf schwarzem Teller")
    ],
    textSections: [
      new SectionContent({
        header: "Das gastronomische 21. Jahrhundert",
        headingLevel: 2,
        paragraphs: [
          "Mit unserem Küchenchef Alexander Dinter erleben Sie in den Räumlichkeiten des ersten Stuttgarter Bahnhofs  Genuss für Augen und Gaumen auf höchstem Niveau in einem einmaligen, unverwechselbaren Rahmen.",
          "Die Nr. 1 bei den Kundenbewertungen von TripAdvisor 2019 und 2020 in Deutschland."
        ]
      })
    ]
  }),
  film: new Sehen({
    name: "Film",
    paragraphsPrePic: [
      "Willkommen in der 5 Welt."
    ],
    imgArr: [],
    videoSrcs: [
      "/video/5.mp4",
      "/video/5_2.mp4"
    ],
    textSections: [
      new SectionContent({
        header: "Im 5 machen alle Disziplinen Spaß",
        headingLevel: 2,
        paragraphs: [
          "Ob Dinner im 5 Gourmet Restaurant, Frühstück, Lunch oder ein kleines Feinschmecker oder Asia/Sushi Menu im 5 Bar/Restaurant."
        ]
      })
    ]
  }),
  freiraum: new Sehen({
    name: "Freiraum",
    paragraphsPrePic: [
      "Unsere Terrasse im Herzen von Stuttgart."
    ],
    imgArr: [
      new ImgData("/img/freiraum1.jpg", "Unsere Terrasse"),
      new ImgData("/img/freiraum5.jpg", "Zubereitung von Gerichten auf der Terrasse", optBGPosT),
      new ImgData("/img/freiraum4.jpg", "Gast auf der Terrasse", optBGPosT),
      new ImgData("/img/freiraum3.jpg", "Gäste im Gespräch", optBGPosT),
      new ImgData("/img/freiraum2.jpg", "Pflanzen und Gäste auf der Terrasse")
    ],
    textSections: [
      new SectionContent({
        header: "Unsere Terrasse Nähe Schlossplatz",
        headingLevel: 2,
        paragraphs: [
          "Genießen Sie sonnige Stunden zum Frühstück, Lunch oder zum Abendessen.",
          "Treffen Sie sich mit Freunden zu einem Aperitivo auf unserer Sonnenterrasse oder wählen Sie aus einer erstklassigen Getränkeauswahl."
        ]
      })
    ]
  }),
  team: new Sehen({
    name: "Team",
    paragraphsPrePic: [
      "Wir verwöhnen Sie jeden Tag von Herzen."
    ],
    imgArr: [
      new ImgData("/img/team2.jpg", "Anja & Michael", optBGPosT),
      new ImgData("/img/team3.jpg", "Mitarbeiter am Tisch", optBGPosT),
      new ImgData("/img/team6.jpg", "Mitarbeiter und 5 Logo"),
      new ImgData("/img/team5.jpg", "3 Mitglieder unseres Teams", optBGPosT),
      new ImgData("/img/team2.jpg", "Anja und Michael", optBGPosT)
    ],
    textSections: [
      new SectionContent({
        header: "Gastgeber aus Leidenschaft",
        headingLevel: 2,
        paragraphs: [
          "Liebe Gäste,<br>wir sind Gastgeber von Herzen und freuen uns über Ihren Besuch.",
          "Herzliche Grüße,<br>Anja Dold und Michael Zeyer und das ganze 5 Team"
        ]
      })
    ]
  }),
  wohnraum: new Sehen({
    name: "Wohnraum",
    paragraphsPrePic: [
      "Unser Bar/Restaurant im Erdgeschoss."
    ],
    imgArr: [
      new ImgData("/img/wohnraum5.jpg", "Gedeckter Tisch im Wohnraum"),
      new ImgData("/img/wohnraum3.jpg", "Wohnraum Panorama"),
      new ImgData("/img/wohnraum1.jpg", "Speisen im Wohnraum"),
      new ImgData("/img/wohnraum2.jpg", "Fleisch mit Gemüse"),
      new ImgData("/img/wohnraum4.jpg", "Drink mit Strohhalm")
    ],
    textSections: [
      new SectionContent({
        header: "Hier machen alle Disziplinen Spaß",
        headingLevel: 2,
        paragraphs: [
          "Ob zum Frühstück, Lunch, Kaffee oder am Abend zu einem Feinschmecker oder Asia/Sushi Menu: wir möchten Sie zu jeder Tageszeit verwöhnen.",
          "Sie finden außerdem eine spannende Auswahl an Getränken."
        ]
      })
    ]
  })
}
