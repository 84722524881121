<template>
  <div class="video-theater">
    <video
      class="video-theater__video"
      controls
      preload="metadata"
      :src="src"
      :style="{width: videoWidth}"
    />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    videoWidth: {
      type: String,
      default: undefined
    }
  }
}
</script>

<styles lang="scss">
@use "img";
@use "vars";

.video-theater {
  background-color: vars.$beige;
  padding: img.$gap 0;
}

.video-theater__video {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
</styles>
